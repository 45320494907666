import React, { lazy, Suspense } from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../assets/scss/plugins/_slick.scss"
import {getCarouselImageOG, getDatesForDisplay} from "../components/common/functions"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import BlockInstagramSlider from "../components/common/blockInstagramSlider/blockInstagramSlider"
import {Col, Container, Row} from "react-bootstrap";
import Heading from "../components/common/heading";
import Reveal from "react-awesome-reveal";
import {keyframes} from "@emotion/react";
import IllusEvent from "./illus-event.webp"

const IndexPage = ({
  data: {
    nodeAccueil,
    allNodeContenuGenerique: eventData,
    allNodeArticleExperience: restaurants,
    allNodeArticleActivite: activities,
  },
  pageContext,
}) => {
  const HeroBanner = lazy(() =>
    import("../components/common/heroBanner/heroBanner")
  )
  const UpcomingEvents = lazy(() =>
    import("../components/common/upcomingEvents/upcomingEvents")
  )
  const BlockHotels = lazy(() =>
    import("../components/common/blockHotels/blockHotels")
  )
  const BlockRestaurants = lazy(() =>
    import("../components/common/blockRestaurants/blockRestaurants")
  )
  const MosaicExperience = lazy(() =>
    import("../components/common/mosaicExperiences/mosaicExperiences")
  )
  const BlockExperiences = lazy(() =>
    import("../components/common/blockExperiences/blockExperiences")
  )
  const BlockMea = lazy(() => import("../components/common/blockMea/blockMea"))
  const BlockMeaMedia = lazy(() =>
    import("../components/common/blockMeaMedia/blockMeaMedia")
  )
  const BlockAnnuaire = lazy(() =>
    import("../components/common/blockAnnuaire/blockAnnuaire")
  )
  const isSSR = typeof window === "undefined"

  const animLeftImg = keyframes`
    from {
      opacity: 0;
      clip-path: inset(0 100% 0 0);
    }
    to {
      opacity: 1;
      clip-path: inset(0);
    }
  `

  const {
    title,
    caption,
    titre,
    field_link,
    field_seo,
    field_intro_cta: cta_title_wista,
    relationships: {
      field_hero: heroItems,
      field_push_article_experience: experiences,
      field_push_contents: hotel,
      field_featured_rubrique: {
        relationships: { featured_rubrique_items },
      },
      field_featured_block_left: blockLeft,
      field_featured_restaurants: restaurantsBlockDetails,
      field_featured_block_right: blockRight,
      field_unforgettable_moments: unforgettableMoment,
      field_intro_media: wistia_homepage,
    },
  } = nodeAccueil

  const intl = useIntl()

  const carouselFiltered = heroItems.filter(
    hero => hero.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  const events = eventData ? eventData.nodes : ""

  let today = new Date()
  const upcomingEvents = events.filter(e => {
    const dateTo = new Date(
      e.relationships.field_event_information.field_start_end_date.end_value
    )
    return today < dateTo
  })

  // const rubriquesInfo = {
  //   title: field_title,
  //   caption: field_caption,
  //   description: field_description,
  //   bgImg: rubriqueBg,
  //   bgHex: field_background_color,
  // }

  const eventBlockInfo = { caption, titre, field_link }

  const blockLeftInfo = {
    title: blockLeft?.featured_block_left_title,
    description: blockLeft?.featured_block_left_description,
    caption: blockLeft?.featured_block_left_caption,
    link: {
      title: blockLeft?.featured_block_left_link.title,
      url: blockLeft?.featured_block_left_link.url,
    },
    media1: blockLeft?.relationships.featured_block_left_main_media,
    media2: blockLeft?.relationships.featured_block_left_sec_media,
  }

  const blockRightInfo = {
    title: blockRight?.featured_block_right_title,
    description: blockRight?.featured_block_right_description,
    caption: blockRight?.featured_block_right_caption,
    link: {
      title: blockRight?.featured_block_right_link.title,
      url: blockRight?.featured_block_right_link.url,
    },
    media1: blockRight?.relationships.featured_block_right_main_media,
    media2: blockRight?.relationships.featured_block_right_sec_media,
  }



  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      {!isSSR && (
        <Suspense fallback="loading.... ">
          {carouselFiltered && <HeroBanner hero={carouselFiltered} />}
          {experiences && (
            <MosaicExperience
              mosaic={experiences}
              cta={cta_title_wista}
              wistia={wistia_homepage}
            />
          )}
          {upcomingEvents.length > 0 ? (
              <>
                <UpcomingEvents
                    eventBlockInfo={eventBlockInfo}
                    events={upcomingEvents}
                />
              </>
          ) : (
              <>
                <section className="blk-listing-events noListingEvent">
                  <Container>
                    <Row className="content-header">
                      <Col md={8}>
                        <Heading
                            caption={intl.formatMessage({ id: "blk_events.caption" })}
                            title={intl.formatMessage({ id: "blk_events.title" })}
                        />
                      </Col>
                      <Col md={4}></Col>
                    </Row>
                  </Container>
                  <Container className="event-list-detail">
                    <Row>
                      <Col md={5} className="image-col">
                        <Reveal
                            delay={0}
                            triggerOnce={true}
                            keyframes={animLeftImg}
                            cascade={true}
                        >
                          <span className="bgdPlaceholder"></span>
                          <div>
                            <img
                                width="488"
                                height="275"
                                src={IllusEvent}
                                alt={intl.formatMessage({ id: "blk_events.caption" })}
                            />
                          </div>
                        </Reveal>
                      </Col>
                      <Col md={7}>
                        <div className="heading">
                          <Heading
                              titleHeadingSize={3}
                              fontStyle={3}
                              title={intl.formatMessage({ id: "blk_events.desc" })}
                            />
                            <div className="description">
                              {intl.formatMessage({ id: "blk_events.desc" })}
                            </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </>
          )}
          {featured_rubrique_items.length && (
            <BlockMea rubriques={featured_rubrique_items} />
          )}
          {hotel && <BlockHotels hotel={hotel} />}
          {blockLeft && <BlockMeaMedia content={blockLeftInfo} />}
          {restaurants.nodes.length > 0 && (
            <BlockRestaurants
              blockInfo={restaurantsBlockDetails}
              items={restaurants.nodes}
            />
          )}
          {blockRight && <BlockMeaMedia content={blockRightInfo} isRight={1} />}
          {activities.nodes.length > 0 && (
            <BlockExperiences
              info={unforgettableMoment}
              items={activities.nodes}
            />
          )}
          <BlockAnnuaire />
          <BlockInstagramSlider />
        </Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    nodeAccueil(title: { ne: "Dummy" }, langcode: { eq: $language }) {
      ...homepageFields
    }
    allNodeContenuGenerique(
      filter: { title: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: {
        fields: relationships___field_event_information___field_start_end_date___value
        order: ASC
      }
    ) {
      ...contenuGeneriqueHomepage
    }
    allNodeArticleExperience(
      filter: {
        title: { ne: "Dummy" }
        langcode: { eq: $language }
        relationships: {
          fieldrubrique: {
            drupal_id: { eq: "f72adacc-af54-4c77-9557-79cc07ed98d6" }
          }
        }
      }
    ) {
      nodes {
        title
        relationships {
          field_featured_media {
            title
            description {
              processed
            }
            relationships {
              media {
                ... on media__image {
                  field_image {
                    alt
                  }
                  internal {
                    type
                  }
                  relationships {
                    ...getImage
                  }
                }
                ... on media__video {
                  ...getVideo
                }
              }
            }
          }
        }
        path {
          langcode
          alias
        }
      }
    }
    allNodeArticleActivite(
      filter: {
        title: { ne: "Dummy" }
        langcode: { eq: $language }
        relationships: {
          fieldrubrique: {
            drupal_id: { eq: "023176ac-3910-4492-b965-9e6beccec34b" }
          }
        }
      }
    ) {
      nodes {
        title
        relationships {
          field_featured_media {
            title
            description {
              processed
            }
            relationships {
              media {
                ... on media__image {
                  field_image {
                    alt
                  }
                  internal {
                    type
                  }
                  relationships {
                    ...getImage
                  }
                }
                ... on media__video {
                  ...getVideo
                }
              }
            }
          }
        }
        path {
          langcode
          alias
        }
      }
    }
  }
`

export default IndexPage
