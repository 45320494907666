import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import Heading from "../heading"
import "./blockInstagramSlider.scss"
import { useIntl } from "gatsby-plugin-react-intl"
//import { graphql, useStaticQuery } from "gatsby"
import Reveal from "react-awesome-reveal"
import { keyframes } from "@emotion/react"
import HTMLReactParser from "html-react-parser"
import { ElfsightWidget } from 'react-elfsight-widget'

const BlockInstagramSlider = () => {
  const intl = useIntl()
  // const animLeftImg = keyframes`
  // from {
  //   opacity: 0;
  //   clip-path: inset(0 100% 0 0);
  // }
  // to {
  //   opacity: 1;
  //   clip-path: inset(0);
  // }
// `
  // const settings = {
  //   infinite: false,
  //   slidesToShow: 2.75,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   draggable: true,
  //   lazyLoad: true,
  //   responsive: [
  //     {
  //       breakpoint: 640,
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "30px",
  //       },
  //     },
  //   ],
  // }
  // const dragClass = useCallback(e => {
  //   const cursor = document.querySelector(".cursor-inner")
  //   cursor.classList.add("drag")
  // }, [])
  // const removeDragClass = useCallback(e => {
  //   const cursor = document.querySelector(".cursor-inner")
  //   cursor.classList.remove("drag")
  // }, [])

  // const data = useStaticQuery(graphql`
  //   query insta {
  //     allInstagramContent(limit: 10) {
  //       nodes {
  //         username
  //         timestamp(formatString: "DD/MM/YYYY")
  //         permalink
  //         localImage {
  //           childImageSharp {
  //             fixed(width: 425, height: 425) {
  //               srcWebp
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // Get Mobile
  // const window1 = typeof window !== "undefined" ? window : ""

  // const [width, setWidth] = useState(window1.innerWidth)

  // function handleWindowSizeChange() {
  //   setWidth(window1.innerWidth)
  // }
  // useEffect(() => {
  //   window1.addEventListener("resize", handleWindowSizeChange)
  //   return () => {
  //     window1.removeEventListener("resize", handleWindowSizeChange)
  //   }
  // }, [])

  // let isMobile = width <= 768

  //console.log(data.allInstagramContent.nodes)
  return (
    <>
      {/* <section className="blk-slider-instagram">
        <Container>
          <Row className="row-slider">
              <Heading
                caption={intl.formatMessage({ id: "follow_us_insta" })}
                title={intl.formatMessage({ id: "title_insta" })}
                description={HTMLReactParser(
                  "@belombremauritius" + "<br/>" + "#belombremauritius"
                )}
              />
              <ElfsightWidget widgetID="965885d4-8b3e-446f-ab68-b1ebaefbaf09" />
          </Row>
        </Container>
      </section> */}
      <section className="blk-slider-instagram">
       <Container className="container-wide">
        <Row className="row-slider">
          <Col md={3}>
            <Heading
                caption={intl.formatMessage({ id: "follow_us_insta" })}
                title={intl.formatMessage({ id: "title_insta" })}
                description={HTMLReactParser(
                  "@belombremauritius" + "<br/>" + "#belombremauritius"
                )}
              />
          </Col>
          <Col md={9}>
            
            <ElfsightWidget widgetID="965885d4-8b3e-446f-ab68-b1ebaefbaf09" />
          </Col>
        </Row>
      </Container> 
    </section>
    </>
  )
}

export default BlockInstagramSlider
